import React, { useState } from 'react';
import Photo from '../Photo';
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/footer.scss";
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { createNewSubscription } from '../../services/tenant-identity-service';
import { MESSAGE } from '../../constant/message-enum';
import { getMessages, getValueMessages } from '../../services/language-service';

function Footer() {
  const context = React.useContext(I18nextContext);
  const language = context.language;
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const initaialEmailValue = { email: "" }
  const [subscribeValue, setSubscribeValue] = useState(initaialEmailValue);
  const [showErrorEmailErrorMessage, setShowErrorEmailMessage] = useState(false);
  const [showSuccessEmailErrorMessage, setShowSuccessEmailMessage] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');

  const handleOnChange = e => {
    const value = { ...subscribeValue };
    value[e.target.name] = e.target.value;
    setSubscribeValue(value);
  }

  const validateEmail = (email) => {
    // Regular expression for email validation
    let validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return validEmailRegex.test(email);
  };

  const clearEmailMessage = () => {
    setEmailMessage("");
    setShowErrorEmailMessage(false);
    setShowSuccessEmailMessage(false);
  }

  const submitSubscribeData = async (event) => {
    event.preventDefault();
    clearEmailMessage();
    let isValid = validateEmail(subscribeValue.email);
    let currentLanguageCode = `${language}`;

    if (isValid) {
      createNewSubscription(subscribeValue)
        .then(async (response) => {
          console.log('response', response);
          let validationMessage = await getMessages(MESSAGE.NEWS_LETTER_SUBSCRIBTION_MESSAGE, currentLanguageCode);
          setEmailMessage(validationMessage);
          setShowSuccessEmailMessage(true);
          setTimeout(() => setShowSuccessEmailMessage(false), 3000);
          setSubscribeValue(initaialEmailValue)
        })
        .catch((error) => {
          console.log('error', error.response);
          let errMessage = error.response.data.message;
          setEmailMessage(errMessage);
          setShowErrorEmailMessage(true);
          setTimeout(() => setShowErrorEmailMessage(false), 3000);
        });
    } else {
      let email = subscribeValue.email;
      let valueFields = { V1: email };
      let validationMessage = await getValueMessages(MESSAGE.INVALID_EMAIL_MESSAGE, currentLanguageCode, JSON.stringify(valueFields));
      setEmailMessage(validationMessage);
      setShowErrorEmailMessage(true);
      setTimeout(() => setShowErrorEmailMessage(false), 3000);
    }
  }
  return (

    <footer className="main_footer" lang={context.language}>
      <div className="container">

        <div className="row">
          <div className="col-sm-12 horizontal_line"></div>
          <div className="col-md-2 col-6">

            <h4 lang={context.language}><Trans>COMPANY</Trans></h4>

            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to="/about-us/"><Trans>About Us</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/contact-us/"><Trans>Contact Us</Trans></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2  col-6">
            <h4 lang={context.language}><Trans>RESOURCES</Trans></h4>
            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to="/blog/"><Trans>Blogs</Trans></Link>
              </li>

              <li lang={context.language}>
                <Link to="/contact-us/"><Trans>24x7 Help Desk</Trans></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3  col-6">
            <h4 lang={context.language}><Trans>OUR PRODUCTS</Trans></h4>
            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to='/product/' language={context.language}><Trans>Freight Forwarding</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/product/warehouse-management-software/"><Trans>3PL & Warehousing</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/product/nvocc-software/"><Trans>NVOCC</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/"><Trans>Movers & Relocation</Trans></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3  col-6">
            <h4 lang={context.language}><Trans>FEATURES</Trans></h4>
            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to="/features/#multilingual"><Trans>Multilingual</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/#multicurrency"><Trans>Multi-Currency</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/#Transportation"><Trans>Transportation</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/#customreport"><Trans>Custom Report Engine</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/#dashboard"><Trans>Dashboard with KPI's & BI's</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/#multipletab"><Trans>Multi-Tab Viewer</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/#Hierarchy"><Trans>Hierarchy Maintenance</Trans></Link>
              </li>
              <li lang={context.language}>
                <Link to="/features/#Alertengine"><Trans>Event Alert Engine</Trans></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2  col-6">
            <h4 lang={context.language}><Trans>SUPPORT</Trans></h4>
            <ul lang={context.language}>
              <li lang={context.language}>
                <Link to="/free-demo/"><Trans>Request Demo</Trans></Link>
              </li>

            </ul>
          </div>
        </div>

        <div className="partner" lang={context.language}>
          <div className="row">
            <div className="col-md-7">

              <div className="getupdated-form" lang={context.language}>

                <h4 lang={context.language}><Trans>Get Updated</Trans></h4>
                <p lang={context.language}><Trans>Subscribe to our newsletter</Trans></p>
              </div>

              <div className="form" lang={context.language}>
                <Form onSubmit={submitSubscribeData} lang={context.language} autoComplete="off">
                  <Form.Group controlId="formBasicEmail" lang={context.language}>
                    <div className="button_align" lang={context.language}>
                      <button type="submit" className="btn sub-btn" lang={context.language}><Trans>SUBSCRIBE</Trans></button>
                      <Form.Control type="email" name="email" value={subscribeValue.email} required="required" onChange={handleOnChange} placeholder={t("Your Email ID")} />
                    </div>
                    {showErrorEmailErrorMessage && (
                      <p className="email_error_message lower_case" lang={context.language}>
                        {emailMessage}
                      </p>
                    )}

                    {showSuccessEmailErrorMessage && (
                      <p className="email_success_message" lang={context.language}>
                        {emailMessage}
                      </p>
                    )}
                  </Form.Group>
                </Form>
              </div>
            </div>


            <div className="col-md-5">
              <div className="links align_link" lang={context.language}>
                <Link to="/free-trial/" className="trial" lang={context.language}
                  onClick={() => localStorage.removeItem("userinfo")}>
                  <Trans>GET A FREE TRIAL</Trans>
                </Link>
                <Link to="/free-demo/" className="demo" lang={context.language}>
                  <Trans>REQUEST A DEMO</Trans>
                </Link>
              </div>
            </div>

            <div className="col-12">
              <div className="border-bott"></div>
            </div>

          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-sm-6" >
              <div className="faq-one">
                <h1 className="faq-page"><Trans>Logistics Solutions</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logistics is nothing more than the vision of how you will get your goods from one place to another. Logistics solutions assist in the management of that entire logistics process. A good logistics software will help you keep track of and manage your shipments, visibility as well as keep you updated on the financial health of your company.</Trans></p>
                  <p><Trans>Logipulse has all this and more. A one-stop logistics management software, Logipulse has many features that would enhance operational efficiency, productivity and scalability while enabling a better experience and value for money making it the ideal logistics management software in the market.</Trans></p>
                </div>
              </div>
              <div className="faq-two">
                <h1 className="faq-page"><Trans>Our Logistics Management Solutions</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse offers a range of logistics solutions apart from freight forwarding. Also featured are 3PL and Warehousing, NVOCC, Movers and Relocation and Customs Clearance. A truly one-stop destination for everything you would need in logistics management software.</Trans></p>
                </div>
              </div>

              <div className="faq-three">
                <h1 className="faq-page"><Trans>Freight Forwarding Solutions</Trans></h1>
                <div className="faq-body">
                  <p><Trans>With Logipulse logistics management software, you can exchange information across in-house personnel, clients and suppliers in  real-time and manage all your logistics operations from a single system.</Trans></p>
                </div>
              </div>

              <div className="faq-three">
                <h1 className="faq-page"><Trans>3PL Solutions</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse software solution for 3PL and warehousing includes all the options you need to process warehouse operations from start to finish, and automatically generate invoices and transfer data to accounting.</Trans></p>
                </div>
              </div>

              <div className="faq-four">
                <h1 className="faq-page"><Trans>NVOCC Solutions</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Our logistics management software aids in booking, keeping track and managing your containers from home port to destination port.  Keeping a track of your financials is also easier with our logistics software.</Trans></p>
                </div>
              </div>
            </div>

            <div className="col-sm-6" >
              <div className="faq-five">
                <h1 className="faq-page"><Trans>Customs Clearance Solutions</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Our logistics solutions for the customs clearance feature allow you to filter or search by specific fields. In addition easy attachment of documents, adding on charges which would auto integrate with the finance module makes this module an easy stop destination for all your customs clearance requirements.</Trans></p>
                </div>
              </div>
              <div className="faq-six">
                <h1 className="faq-page"><Trans>Movers & Relocation Software</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse facilitates paperless operations which automate and integrate multiple functions, from the survey, packing, shipping, billing, accounting, vendor management and storage, into one well-organized platform. Our logistics software facilitates all this and more…</Trans></p>
                </div>
              </div>


              <div className="faq-six">
                <h1 className="faq-page"><Trans>Major Features of Logipulse Logistics Solutions</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse is an end-to-end logistics management software with superior technology. Our logistics solutions are aimed at simplifying everyday operations in all aspects of freight forwarding. Our standout features are our multi-language interface, multi-location, multi-currency finance module and customised report engine and Data Analytical KPI Dashboard which would save time usually spent on customising reports from your service provider.</Trans></p>
                  <p><Trans>Another unique feature is the availability of our logistics software over different platforms such as Desktop, Tablet, Mobile, as well as PDA and Scanning devices, making working on the move easy and accessible.</Trans></p>
                </div>
              </div>


              <div className="faq-six">
                <h1 className="faq-page"><Trans>Benefits of Logistics Solutions for Businesses</Trans></h1>
                <div className="faq-body">
                  <p><Trans>The benefits of a good logistics management software are hassle-free operations, good warehouse and inventory control, freight management and finances under one platform. All these improve performance, productivity and efficiency as well as keep costs under control which make for a better customer experience.</Trans></p>
                </div>
              </div>

              <div className="faq-six">
                <h1 className="faq-page"><Trans>What Makes Logipulse Unique?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse is a good and wholesome logistics solution that will tide over the constantly fast-changing demands of the industry. Featuring many unique tools that have been designed after 10 years of research and understanding the nuances of the market to build a logistics software that will last for the next 5 years.</Trans></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1 className="faq-heading"><Trans>FAQ'S</Trans></h1>
        {/* <div class="container"> */}
        <div>
          <div className="row">
            <div className="col-sm-6" >
              <div className="faq-one">
                <h1 className="faq-page"><Trans>What is a logistics management system?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>A logistics management system is a combination of software tools that optimise logistics processes from pickup to delivery. A good logistics software would include key factors of operations, inventory control, transportation of freight, tracking and finance.</Trans></p>

                </div>
              </div>
              <div className="faq-two">
                <h1 className="faq-page"><Trans>What is the importance of logistics software?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Timely and accurate information and conveying this information are essential and vital in logistics. Logipulse, a good logistics management software, enables better visibility, decision making, and productivity and reduces costs.</Trans></p>
                </div>
              </div>

              <div className="faq-three">
                <h1 className="faq-page"><Trans>What are the major activities involved in logistics systems?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>A good logistics solution would oversee the proper management of shipment booking processing, warehouse management, handling and transportation of cargo, inventory control and  last-mile & delivery.</Trans></p>
                </div>
              </div>


            </div>
            <div className="col-sm-6" >
              <div className="faq-four">
                <h1 className="faq-page"><Trans>How is Logipulse different from other freight forwarding software?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse is a cloud-hosted SaaS logistics software, an end-to-end solution for logistics companies, with superior technology aimed at small to medium freight forwarders.</Trans></p>
                </div>
              </div>
              <div className="faq-five">
                <h1 className="faq-page"><Trans>Is the logistics system available in multiple languages?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>Logipulse is the only SaaS-based logistics software that supports multiple languages – you can switch  between languages easily. Keeping in mind that most logistics operations often are across several borders, our logistics management software makes it easier to liaise between branches across the globe.</Trans></p>
                </div>
              </div>
              <div className="faq-six">
                <h1 className="faq-page"><Trans>What do logistics systems provide?</Trans></h1>
                <div className="faq-body">
                  <p><Trans>A good logistics management software will include management of import and export freight, warehouse management and inventory control, freight carrier management and managing third-party logistics service providers.</Trans></p>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* </div> */}

        <div className="col-12">
          <div className="border-bott"></div>
        </div>

        <div className="footer-bottom" lang={context.language}>
          <div className="row">
            <div className="col-md-2 powerdedby" lang={context.language}>
              <p className="power" lang={context.language}><Link to="/"><Trans>Powered by</Trans></Link></p>

              <Photo src="Stellosysyw-Logo.svg" alt="" className="logo" />
            </div>
            <div className="col-md-3  contactby">
              <div className="contact" lang={context.language}>
                <a href="tel:+91 9895604656" className="phone" lang={context.language}>
                  <Photo src="phone-24px.svg" alt="" className="" />
                  + 91 9895604656
                </a>

                <a href="mailto:sales@logipulse.com" className="email" lang={context.language} style={{ cursor: "pointer" }}>
                  <Photo src="email-24px.svg" lang={context.language} alt="" className="" />
                  <Trans>sales@logipulse.com</Trans>
                </a>
              </div>
            </div>
            <div className="col-md-5 contactedby" lang={context.language}>
              <div className="contacts-seconds" lang={context.language}>
                <Link to="/terms-and-conditions/" className="terms"><Trans>Terms of Use</Trans></Link>
                <Link to="/privacy-policy/" className="privacy" lang={context.language}><Trans>Privacy Policy</Trans></Link>
                <p className="copy_right" lang={context.language}><Trans>Copyright @ 2021 Stellosys</Trans><br></br><Trans>All rights Reserved</Trans></p>

              </div>
            </div>
            <div className="col-md-2 contacted-icons" lang={context.language}>
              <div className="facebook" lang={context.language}>
                <a href="https://www.facebook.com/Logipulse" target="_blank">
                  <Photo src="FB-SM Icon.svg" alt="facebook" className="" />
                </a>
              </div>
              <div className="instagram" lang={context.language}>
                <a href="https://www.instagram.com/logipulse" target="_blank">
                  <Photo src="IG-SM Icon.svg" alt="instagram" className="" />
                </a>
              </div>
              <div className="linkedin" lang={context.language}>
                <a href="https://www.linkedin.com/showcase/logipulse" target="_blank">
                  <Photo src="IN-SM Icon.svg" alt="linkedin" className="" />
                </a>
              </div>
              <div className="youtube" lang={context.language}>
                <a href="https://www.youtube.com/channel/UCySWPDX9gsXloqXo4nadoWg" target="_blank">
                  <Photo src="YT-SM Icon.svg" alt="youtube" className="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whatsapp_main" lang={context.language}>
        <div className="whattsapp" lang={context.language}>
          <a href="http://wa.me/+919895604656" target="_blank">
            <Photo src="whatsapp_black_24dp.svg" alt="whattsapp" className="" />
          </a>
        </div>

        <div className="chatpot" lang={context.language}>
          <a href="#" target="_blank">
            <Photo src="question_answer_black_24dp.svg" alt="" className="" />
          </a>
        </div>

      </div>
      <Modal show={show} onHide={handleClose} className="modaldialog">
        <Modal.Header closeButton>
          <Modal.Title>EMAIL SUBSCRIPTION</Modal.Title>
          <div className="line"></div>
        </Modal.Header>

        <Modal.Body>
          <CheckCircleOutlineOutlinedIcon className="material-stesting" />
          {/* <Photo
                             src="check_circle_black_24dp.svg"
                                className="material-stesting"
                                alt=""
                              />         */}
          <div className="email_content" placeholder="abcd@example.com">abcd@example.com</div>
          <div className="smodal-subcontent">Your subscription to our newsletter has been successful !</div>
          <div className="close-btn">ok</div>
          <div className="modal-subcontents"><span className="unsubscribe">Unsubscribe</span></div>

        </Modal.Body>

      </Modal>
    </footer>

  )
}

export default Footer
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
