import jwt_decode from 'jwt-decode';

const GATSBY_DOMAIN = process.env.GATSBY_DOMAIN;

const getIdTokenFromCookie = async () => {
    let idToken = '';
    let cookieValue = `; ${document.cookie}`;
    let splitCookieByName = [];

    if (cookieValue !== "") {
        splitCookieByName = cookieValue.split(`;`);
        splitCookieByName.forEach((obj) => {
            if (obj[(obj.search("idToken"))] === 'i') {
                let idTokenPositionNo = obj.search("idToken");
                idToken = obj.substr(idTokenPositionNo + 8, obj.length - 1)
            }
        });
    }

    return await idToken;
}

const getTokenDetailsByDecoding = (token) => {

    if (token !== undefined && token !== null && token !== '') {
        let decodedToken = jwt_decode(token);

        let tenantGuid = decodedToken['custom:tenantGuid'];
        let tenantUserGuid = decodedToken['custom:tenantUserGuid'];
        let locationGuid = decodedToken['custom:locationGuid'];
        let currencyGuid = decodedToken['custom:currencyGuid'];
        let tenantUserRole = decodedToken['custom:tenantUserRole'];
        let tokenExperiation = decodedToken['exp'];
        let isTrialUser = decodedToken['custom:isTrialUser'];

        return { tenantGuid, tenantUserGuid, locationGuid, currencyGuid, tenantUserRole, tokenExperiation, isTrialUser };
    } else {
        return null;
    }
}

const deleteCookie = async () => {
    let domainName = process.env.GATSBY_DOMAIN;
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.logipulse.com;";
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=' + domainName;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.dev.logipulse.com;';
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=www.dev.logipulse.com;';
    }
}

const deleteCognitoTokenFromLocalStorage = async () => {
    for (let obj in localStorage) {
        if (obj.includes("CognitoIdentityServiceProvider")) {
            localStorage.removeItem(obj);
        }
    }

    localStorage.removeItem('amplify-signin-with-hostedUI');
}

const setCookie = (name, value) => {
    let domainName = GATSBY_DOMAIN;
    let date = new Date();
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + "; domain=" + domainName + "; path=/";
}

const isTokenExpired = async (idTokenFromCookie) => {
    let decodedToken = getTokenDetailsByDecoding(idTokenFromCookie);

    if (decodedToken != null) {
        let expirationTimeInSecond = decodedToken.tokenExperiation;
        let currentTimeInSecond = Math.floor((new Date).getTime() / 1000);

        return currentTimeInSecond >= expirationTimeInSecond;
    } else {
        return true;
    }
}

export {
    getIdTokenFromCookie,
    getTokenDetailsByDecoding,
    deleteCookie,
    deleteCognitoTokenFromLocalStorage,
    setCookie,
    isTokenExpired
}