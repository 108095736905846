import React, { useEffect, useState, useCallback } from "react";
import "../styles/signin.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import SuccessAlert from '../components/common/SuccessAlert';
import ErrorAlert from "../components/common/ErrorAlert";
import { getMessages } from '../services/language-service';
import { getBranchLocation } from '../services/branch-service';
import { getTrialBranchLocation } from '../services/trial-branch-service';
import { getIdTokenFromCookie, isTokenExpired, getTokenDetailsByDecoding } from '../services/cookie-storage-service';
import * as uuid from "uuid";
import { MESSAGE } from '../constant/message-enum';
import { graphql, navigate } from "gatsby";
import Photo from '../components/Photo';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Fragment } from 'react';
import { TrialUserEnum } from '../constant/default-enum';
import { getPoolInformation } from '../services/tenant-identity-service';
import { getTrialPoolInformation } from '../services/trial-tenant-identity-service';
import awsPoolConfiguration from "../aws-exports";
import { Auth, Amplify } from "aws-amplify";

const initialFormValue = {
    locationGuid: uuid.NIL
}
const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;
const GATSBY_TRIAL_APPLICATION_URL = process.env.GATSBY_TRIAL_APPLICATION_URL;
const GATSBY_LOGIPULSE_APPLICATION_URL = process.env.GATSBY_LOGIPULSE_APPLICATION_URL;
const ENGLISH_LANGUAGE_GUID = process.env.ENGLISH_LANGUAGE_GUID;

function Location(props) {
    const { language } = React.useContext(I18nextContext);
    const { t } = useTranslation();
    const propsValue = props.location.state;
    const buildCanonicalUrl = () =>
        language === "en"
            ? GATSBY_LANDING_PAGE_URL + "/location/"
            : GATSBY_LANDING_PAGE_URL + `/${language}/location/`;

    const canonicalUrl = buildCanonicalUrl();

    const buildHrefUrl = () =>
        language === "en"
            ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
            : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
    const hrefUrl = buildHrefUrl();


    const [formValues, setformValues] = useState(initialFormValue);
    const [alert_type, setAlertType] = useState("");
    const [alert_message, setAlertMessage] = useState('');
    const [locationArr, setlocationArr] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingBranch, setBranchLoading] = useState(false);

    const getLanguageGuidByCode = async (currentLanguageCode) => {
        let languageGuid = ENGLISH_LANGUAGE_GUID;
        let langFromLocalStore = JSON.parse(localStorage.getItem('languages'));
        console.log('langFromLocalStore++++++++++++', langFromLocalStore);

        if (langFromLocalStore != undefined && langFromLocalStore.length > 0) {
            languageGuid = langFromLocalStore.find(language => language.Code == currentLanguageCode).LanguageId;
        }

        return languageGuid;
    }

    const getLocation = useCallback(async () => {
        let locationResult = [];
        try {
            setBranchLoading(true);
            let currentLanguageCode = `${language}`;
            let languageGuid = await getLanguageGuidByCode(currentLanguageCode);
            // let authenticatedUser = await Auth.currentUserInfo();
            // let userCustomAttribute = authenticatedUser.attributes;
            // let isTrialUser = userCustomAttribute['custom:isTrialUser'];
            let idTokenFromCookie = await getIdTokenFromCookie();

            if (idTokenFromCookie === "") {
                if (currentLanguageCode === 'en') {
                    navigate("/login");
                }
                else {
                    navigate(`/${currentLanguageCode}/login`);
                }
            } else {
                let tokenDetails = getTokenDetailsByDecoding(idTokenFromCookie);
                let isTrialUser = tokenDetails.isTrialUser;

                if (isTrialUser === undefined || isTrialUser === TrialUserEnum.NO) {
                    console.log('getBranchLocation function before', new Date());
                    let response = await getBranchLocation(languageGuid, currentLanguageCode);
                    console.log('getBranchLocation function after', new Date());
                    locationResult = response.data.getBranch.map(value => {
                        return { value: value.GroupGuid, display: value.OfficeName, currencyGuid: value.CurrencyGuid }
                    });
                    setBranchLoading(false);
                } else {
                    console.log('getTrialBranchLocation function before', new Date());
                    let response = await getTrialBranchLocation(languageGuid, currentLanguageCode);
                    console.log('getTrialBranchLocation function after', new Date());
                    locationResult = response.data.getBranch.map(value => {
                        return { value: value.GroupGuid, display: value.OfficeName, currencyGuid: value.CurrencyGuid }
                    });
                    setBranchLoading(false);
                }

                // if (authenticatedUser != null && authenticatedUser != undefined) {
                //     await readLocationData();
                // } else {
                //     await configureAmplify();
                // }
            }
        } catch (error) {
            setBranchLoading(false);
        }

        return locationResult;
    }, [language]);

    const readLocationData = async () => {
        let locationResult = [];
        let currentLanguageCode = `${language}`;
        let languageGuid = await getLanguageGuidByCode(currentLanguageCode);
        let idTokenFromCookie = await getIdTokenFromCookie();
        let tokenDetails = getTokenDetailsByDecoding(idTokenFromCookie);
        let isTrialUser = tokenDetails.isTrialUser;


        if (isTrialUser === undefined || isTrialUser === TrialUserEnum.NO) {
            let response = await getBranchLocation(languageGuid, currentLanguageCode);
            locationResult = response.data.getBranch.map(value => {
                return { value: value.GroupGuid, display: value.OfficeName, currencyGuid: value.CurrencyGuid }
            });
            setBranchLoading(false);
        } else {
            console.log('getTrialBranchLocation function before', new Date());
            let response = await getTrialBranchLocation(languageGuid, currentLanguageCode);
            locationResult = response.data.getBranch.map(value => {
                return { value: value.GroupGuid, display: value.OfficeName, currencyGuid: value.CurrencyGuid }
            });
            setBranchLoading(false);
            console.log('getTrialBranchLocation function after', new Date());
        }
    }

    const configureAmplify = async () => {
        let poolResult = [];
        let currentLanguageCode = `${language}`;
        let idTokenFromCookie = await getIdTokenFromCookie();

        if (idTokenFromCookie === "") {
            if (currentLanguageCode === "en") {
                navigate("/login")
            } else {
                navigate(`/${currentLanguageCode}/login`)
            }
        } else {
            let isUserTokenExpired = await isTokenExpired(idTokenFromCookie);
            console.log('isUserTokenExpired++++++++', isUserTokenExpired);

            if (!isUserTokenExpired) {
                let tokenDetails = getTokenDetailsByDecoding(idTokenFromCookie);
                console.log('tokenDetails++++++++', JSON.stringify(tokenDetails));

                if (tokenDetails !== undefined && tokenDetails !== null) {
                    if (tokenDetails.isTrialUser !== undefined && tokenDetails.isTrialUser === "YES") {
                        poolResult = await getTrialPoolInformation(tokenDetails.tenantGuid);
                    } else {
                        poolResult = await getPoolInformation(tokenDetails.tenantGuid);
                    }

                    console.log('poolResult+++++++++', JSON.stringify(poolResult));

                    if (poolResult !== null && poolResult !== undefined && Object.keys(poolResult).length > 0) {
                        awsPoolConfiguration.aws_project_region = poolResult.region;
                        awsPoolConfiguration.aws_cognito_identity_pool_id = poolResult.identityPoolId;
                        awsPoolConfiguration.aws_cognito_region = poolResult.region;
                        awsPoolConfiguration.aws_user_pools_id = poolResult.userPoolId;
                        awsPoolConfiguration.aws_user_pools_web_client_id = poolResult.clientId;

                        console.log('awsPoolConfiguration', awsPoolConfiguration);
                        Amplify.configure(awsPoolConfiguration);
                        Auth.configure(awsPoolConfiguration);
                    }
                }
            } else {
                if (currentLanguageCode === "en") {
                    navigate("/login")
                } else {
                    navigate(`/${currentLanguageCode}/login`)
                }
            }
        }
    }

    useEffect(() => {
        let isApiSubscribed = true;
        let currentLanguageCode = `${language}`;
        if (localStorage.getItem('gatsby-i18next-language')) {
            localStorage.removeItem('gatsby-i18next-language');
        }

        if (propsValue !== null && propsValue !== undefined && propsValue.hasOwnProperty('isRegisterationCompleted')) {
            getMessages(MESSAGE.SUCCESSFULL_REGISTERATION_LOGIN_MESSAGE, currentLanguageCode).then((response) => {
                let message = response;
                setAlertType("SUCCESS");
                setAlertMessage(message);
            }).catch((error) => {
                console.log(error);
            });
        }

        getLocation()
            .then((response) => {
                if (isApiSubscribed) {
                    console.log('useEffect getLocation after', new Date());
                    let initalArr = [{ value: '', display: 'Select Location' }];
                    let finalArr = initalArr.concat(response);
                    setlocationArr(finalArr);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        return () => {
            // cancel the subscription
            isApiSubscribed = false;
        };
    }, [propsValue, language, getLocation]);

    const handleChange = (event) => {
        const value = { ...formValues };
        value[event.target.name] = event.target.value;
        setformValues(value);
    }

    const setCookie = (name, value) => {
        let domainName = process.env.GATSBY_DOMAIN;
        let date = new Date();
        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + "; " + expires + "; domain=" + domainName + "; path=/";
    }

    const clearAlertType = () => {
        setAlertType("");
        setAlertMessage("");
    }

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            clearAlertType();
            let idTokenFromCookie = await getIdTokenFromCookie();
            let currentLanguageCode = `${language}`;
            console.log('handleSubmit before', new Date());

            if (idTokenFromCookie === "") {
                if (currentLanguageCode === 'en') {
                    navigate("/login");
                }
                else {
                    navigate(`/${currentLanguageCode}/login`);
                }
            } else {
                setLoading(true);
                let locationList = locationArr;
                let selectedData = locationList.filter((obj) => {
                    return obj.value.toLowerCase() === formValues.locationGuid.toLowerCase()
                });

                if (selectedData.length > 0) {
                    let locationObj = selectedData[0];

                    let locationCurrencyGuid = locationObj.currencyGuid;
                    let user = await Auth.currentAuthenticatedUser();
                    await Auth.updateUserAttributes(user, {
                        'custom:locationGuid': formValues.locationGuid,
                        'custom:currencyGuid': locationCurrencyGuid
                    });
                    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
                    const currentSession = await Auth.currentSession();
                    cognitoUser.refreshSession(currentSession.refreshToken);

                    let tokenDetails = getTokenDetailsByDecoding(idTokenFromCookie);
                    let isTrialUser = tokenDetails.isTrialUser;

                    if (isTrialUser !== undefined) {
                        window.location.replace(GATSBY_TRIAL_APPLICATION_URL + "/logipulse/mail-inbox/inbox");
                    } else {
                        window.location.replace(GATSBY_LOGIPULSE_APPLICATION_URL + "/logipulse/mail-inbox/inbox");
                    }
                }
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const handleMouseMoveEvent = async (event) => {
        console.log('handleMouseMoveEvent++++++', event.type);

        let currentLanguageCode = `${language}`
        let idTokenFromCookie = await getIdTokenFromCookie();

        if (idTokenFromCookie === "") {
            if (currentLanguageCode === "en") {
                navigate("/login")
            } else {
                navigate(`/${currentLanguageCode}/login`)
            }
        }
    }

    return (
        <Fragment>
            <Helmet>
                <Link rel="canonical" href={canonicalUrl} />
                <Link rel="alternate" href={hrefUrl} hreflang={language} />
            </Helmet>
            <main className="purchase-page" lang={language}>
                <div className="purchase-page-div location_page_height" lang={language}>
                    <div className="container" lang={language}>
                        {
                            alert_type === 'SUCCESS' ? (<SuccessAlert message={alert_message} />) : null
                        }

                        {
                            alert_type === 'ERROR' ? (<ErrorAlert message={alert_message} />) : null
                        }

                        <div className="row purchase-total" lang={language}>
                            <div className="col-md-6">
                                <div className="contents ipadlocation" lang={language}>
                                    <Link to="/" lang={language}>{" "}<Photo src="LogiPulse-Logo-H48.svg" className="photo-size" alt="" />{" "}</Link>
                                    <p><Trans>Sign in using your credentials to purchase or to view the details of your subscription or to continue using the app.</Trans></p>
                                    <Link to="/purchase/" onClick={() => localStorage.removeItem("userinfo")} className="sign-up signpurchase purchasesignin"><Trans>Buy License</Trans></Link>
                                </div>
                            </div>
                            <div className="col-md-6" onMouseMove={handleMouseMoveEvent}>
                                <div className="contents" lang={language}>

                                    {/* <!-- multistep form --> */}
                                    <div className="purchase_heading_section" lang={language}>
                                        <div className="profile" lang={language}><b><Trans>Sign In</Trans></b></div>
                                    </div>
                                    <Form onSubmit={handleSubmit} autoComplete="off">
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            {isLoadingBranch && (
                                                <Photo src="transparent-loader.png" className="select_control_loader" alt="" />
                                            )}
                                            <Form.Label><Trans>Agency</Trans></Form.Label>
                                            <Form.Control as="select" required name="locationGuid" value={formValues.locationGuid} onChange={handleChange}>
                                                {locationArr && locationArr.map((elmt) => <option key={elmt.value} value={elmt.value}>{t(elmt.display)}</option>)}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group className="sign-bottom-section" controlId="formBasicCheckbox" lang={language}>
                                            <div className="chkbox_btn_align" lang={language}>
                                                <div className="chkbox"></div>
                                                <div className="signin_btn" lang={language}>
                                                    <button className="submit_button_common font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative" type="submit" disabled={isLoading}>
                                                        <Trans>Submit</Trans>
                                                        {isLoading && (
                                                            <Photo src="loader_64.gif" className="spinner" alt="" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="purchase-footer" lang={language}>
                    <div className="purchase-footer-bottom" lang={language}>
                        <div className="container">

                            <div className="row align-content-center" lang={language}>
                                <div className="col-md-4 power">
                                    <div><Trans>Powered by</Trans></div>
                                    <Photo src="Stellosysyw-Logo.svg" alt="" className="powered_logo" />
                                </div>
                                <div className="col-md-4 copyright" lang={language}>
                                    <div>
                                        <Trans>Copyright 2021 Stellosys</Trans><br /><Trans>All rights Reserved.</Trans>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer>
            </main>
        </Fragment>
    )
}

export default Location;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`