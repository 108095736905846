import axios from 'axios';

let languageServiceUrl = process.env.GATSBY_LANGUAGE_SERVICE_URL;
let test = process.env.GATSBY_ENVIRONMENT_NAME;

console.log("language",languageServiceUrl);
console.log("test",test);

const getLanguageGuid = async (code) => {
    let apiURL = `${languageServiceUrl}/languageService/language`;

    let response = await axios.get(apiURL);
    let languageArr = response.data.dataObject;

    let languageObj = languageArr.find((obj) => {
        return obj.Code.toUpperCase() === code.toUpperCase();
    });

    return languageObj.LanguageId.toUpperCase();
}

const getAllLanguages = async (code) => {
    let apiURL = `${languageServiceUrl}/languageService/language`;

    console.log("apiURL",apiURL);

    let response = await axios.get(apiURL);
    let languageArr = response.data.dataObject;

    return languageArr;
}

const getWebsiteLanguages = async (code) => {
    let apiURL = `${languageServiceUrl}/languageService/websiteLanguage`;
    let response = await axios.get(apiURL);
    return response.data.dataObject;
}

const getMessages = async (messageId, languageCode) => {
    let apiURL = `${languageServiceUrl}/languageService/responseEngine`;
    let queryParam = {
        params: {
            messageId: messageId,
            languageCode: languageCode
        }
    }

    let response = await axios.get(apiURL, queryParam);
    let message = response.data.message;
    return message;
}

const getValueMessages = async (messageId, languageCode, values) => {
    let apiURL = `${languageServiceUrl}/languageService/responseEngine`;
    let queryParam = {
        params: {
            messageId: messageId,
            languageCode: languageCode,
            values: values
        }
    }

    let response = await axios.get(apiURL, queryParam);
    let message = response.data.message;
    return message;
}

const getFieldConsoleMessages = async (messageId, languageCode, fieldsConsole) => {
    let apiURL = `${languageServiceUrl}/languageService/responseEngine`;
    let queryParam = {
        params: {
            messageId: messageId,
            languageCode: languageCode,
            fieldsConsole: JSON.stringify(fieldsConsole)
        }
    }

    let response = await axios.get(apiURL, queryParam);
    let message = response.data.message;
    return message;
}


export {
    getLanguageGuid,
    getAllLanguages,
    getMessages,
    getValueMessages,
    getFieldConsoleMessages,
    getWebsiteLanguages
}