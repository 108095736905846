import React, { Fragment, useEffect, useState } from "react";
import "../styles/loginpage.scss";
import "../styles/signin.scss";
import Photo from "../components/Photo";
import Form from "react-bootstrap/Form";
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { Auth, Amplify } from "aws-amplify";
import { navigate } from "gatsby";
import awsPoolConfiguration from "../aws-exports";
import { getLoginUserPoolInformation, getRegisteredUserByEmail, resendTemporaryPasssword } from "../services/tenant-identity-service";
import { getTrialUserPoolInformation, resendTemporaryPassswordForTrialUser } from "../services/trial-tenant-identity-service";
import "bootstrap/dist/css/bootstrap.min.css";
import SuccessAlert from "../components/common/SuccessAlert";
import ErrorAlert from "../components/common/ErrorAlert";
import { Helmet } from "react-helmet";
import { CONFIRMED, FORCE_CHANGE_PASSWORD } from "../constant/user-login-status-enum";
import { useTranslation } from "react-i18next";
import { MESSAGE } from '../constant/message-enum';
import { getMessages, getValueMessages } from '../services/language-service';


const initialFormValue = {
  code: "",
  email: "",
  password: "",
  confirmPassword: ""
}

function ForgotPassword() {
  const { language } = React.useContext(I18nextContext);
  const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;

  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/login/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/login/`

  const canonicalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
  const hrefUrl = buildHrefUrl();

  const { t } = useTranslation();

  const [alert_type, setAlertType] = useState("");
  const [alert_message, setAlertMessage] = useState("");
  const [formValues, setformValues] = useState(initialFormValue);
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [passwordMisMatchError, setPasswordMisMatchErrMessage] = useState("");
  const [passwordMisMatch, setPasswordMisMatch] = useState(false);
  const [showEyeOption, setShowEyeOption] = useState(false);
  const [showConfirmPasswordEyeOption, setShowConfirmPasswordEyeOption] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [userTableData, setUserTableData] = useState({});
  let timer = null;

  useEffect(() => {

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const value = { ...formValues };
    value[event.target.name] = event.target.value;
    setformValues(value);

    if (fieldName === 'password') {
      setShowEyeOption(true);
      setShowPassword(false);
    } else if (fieldName === 'confirmPassword') {
      setShowConfirmPasswordEyeOption(true);
      setShowConfirmPassword(false);
    }
  }

  const clearAlertType = () => {
    setAlertType("");
    setAlertMessage("");
  }

  const navigateToLoginPage = async () => {
    let currentLanguage = `${language}`;

    if (currentLanguage === "en") {
      navigate("/login")
    } else {
      navigate(`/${currentLanguage}/login`)
    }

    setIsSendingCode(false);
  }

  async function sentVerificationCode(event) {
    try {
      event.preventDefault();
      clearAlertType();
      let userResult = [];
      let currentLanguage = `${language}`;
      setIsSendingCode(true);
      let registeredUserList = await getRegisteredUserByEmail(formValues.email);

      if (registeredUserList.length > 0) {
        let registeredUserResult = registeredUserList[0];

        if (registeredUserResult.is_Trial) {
          userResult = await getTrialUserPoolInformation(formValues.email);
        } else {
          userResult = await getLoginUserPoolInformation(formValues.email);
        }

        console.log('userResult+++++++', userResult);

        if (userResult.length > 0) {
          let userData = userResult[0];
          await setUserTableData(userData);
          let tenantResult = userData.Tenant;
          let userStatus = userData.userConfirmed;
          let isTrialPool = tenantResult.isTrialPool;

          if (userStatus === FORCE_CHANGE_PASSWORD) {
            let resendTemporaryPassswordResponse = {};
            let resendPasswordObj = {
              'email': formValues.email
            }

            if (isTrialPool) {
              resendTemporaryPassswordResponse = await resendTemporaryPassswordForTrialUser(resendPasswordObj);
            } else {
              resendTemporaryPassswordResponse = await resendTemporaryPasssword(resendPasswordObj);
            }

            console.log('resendTemporaryPassswordResponse', JSON.stringify(resendTemporaryPassswordResponse));

            let validationMessage = await getMessages(MESSAGE.TEMPORARY_PASSWORD_RESEND_MESSAGE, currentLanguage);
            setAlertType("SUCCESS");
            setAlertMessage(validationMessage);
            timer = setTimeout(() => navigateToLoginPage(), 8000);
          } else if (userStatus === CONFIRMED) {
            awsPoolConfiguration.aws_project_region = tenantResult.region;
            awsPoolConfiguration.aws_cognito_identity_pool_id = tenantResult.identityPoolId;
            awsPoolConfiguration.aws_cognito_region = tenantResult.region;
            awsPoolConfiguration.aws_user_pools_id = tenantResult.userPoolId;
            awsPoolConfiguration.aws_user_pools_web_client_id = tenantResult.clientId;

            console.log('awsPoolConfiguration+++++++++++', JSON.stringify(awsPoolConfiguration));

            Amplify.configure(awsPoolConfiguration);
            Auth.forgotPassword(userData.email).then(async (data) => {
              console.log('forgotPassword result', JSON.stringify(data));
              let result = data;
              let deliveryMedium = result.CodeDeliveryDetails.DeliveryMedium;
              let destination = result.CodeDeliveryDetails.Destination;
              let validationMessage = "";

              if (deliveryMedium === 'SMS') {
                let valueFields = { V1: destination };
                validationMessage = await getValueMessages(MESSAGE.OTP_SENT_TO_MOBILE_NUMBER, currentLanguage, JSON.stringify(valueFields));
              } else if (deliveryMedium === 'EMAIL') {
                let valueFields = { V1: destination };
                validationMessage = await getValueMessages(MESSAGE.OTP_SENT_TO_MAIL, currentLanguage, JSON.stringify(valueFields));
              }

              setAlertType("SUCCESS");
              setAlertMessage(validationMessage);
              setCodeSent(true);
            }).catch((err) => {
              console.log('forgotPassword error', err);
              setAlertType("ERROR");
              setAlertMessage(err.message);
              setIsSendingCode(false);
            })
          } else {
            let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
            setAlertType("ERROR");
            setAlertMessage(validationMessage);
            setIsSendingCode(false);
          }
        }
      } else {
        let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
        setAlertType("ERROR");
        setAlertMessage(validationMessage);
        setIsSendingCode(false);
      }
    } catch (error) {
      console.log('sentVerificationCode error+++++++', JSON.stringify(error));
      let errorMessage = error.hasOwnProperty('errorMessage') ? error.errorMessage : error.message;
      setIsSendingCode(false);
      setAlertType("ERROR");
      setAlertMessage(errorMessage);
    }
  }

  async function confirmVerificationCode(event) {
    try {
      console.log('confirmverificationCode result++++++', userTableData);
      event.preventDefault();
      clearAlertType();
      let currentLanguage = `${language}`;
      setPasswordMisMatchErrMessage("");
      setPasswordMisMatch(false);
      if (formValues.password !== formValues.confirmPassword) {
        let validationMessage = await getMessages(MESSAGE.PASSWORD_DO_NOT_MATCH, currentLanguage);
        setPasswordMisMatchErrMessage(validationMessage);
        setPasswordMisMatch(true);
      } else {
        setIsConfirming(true);
        Auth.forgotPasswordSubmit(
          userTableData.email,
          formValues.code,
          formValues.password
        ).then(async (data) => {
          setConfirmed(true);
          let validationMessage = await getMessages(MESSAGE.PASSWORD_RESET_SUCCESS_MESSAGE, currentLanguage);
          setAlertType("SUCCESS");
          setAlertMessage(validationMessage);
          if (currentLanguage === "en") {
            navigate("/login")
          } else {
            navigate(`/${currentLanguage}/login`)
          }
        }).catch((err) => {
          console.log('confirmation err+++++', err);
          setIsConfirming(false);
          setAlertType("ERROR");
          setAlertMessage(err.message);
        });
      }
    } catch (error) {
      console.log('confirmVerificationCode error+++++++', error);
      setIsConfirming(false);
      setAlertType("ERROR");
      setAlertMessage(error.message);
    }
  }

  const showPasswordValue = (event) => {
    event.preventDefault();
    setShowPassword(false);
    setPasswordType('password');
  }

  const hidePasswordValue = (event) => {
    event.preventDefault();
    setShowPassword(true);
    setPasswordType('text');
  }

  const showConfirmPasswordValue = (event) => {
    event.preventDefault();
    setShowConfirmPassword(false);
    setConfirmPasswordType('password');
  }

  const hideConfirmPasswordValue = (event) => {
    event.preventDefault();
    setShowConfirmPassword(true);
    setConfirmPasswordType('text');
  }

  function renderRequestCodeForm() {
    return (
      <Form onSubmit={sentVerificationCode} autoComplete="off">
        <Form.Group controlId="email">
          <div className="input_custom_form_style">
            <Form.Label>
              <Trans>Email</Trans>
            </Form.Label>
          </div>
          <Form.Control
            autoFocus
            type="email"
            name="email"
            required
            onChange={handleChange}
            value={formValues.email}
            className="input_style"
          />
        </Form.Group>

        <Form.Group
          className="sign-bottom-section"
          controlId="formSentVerificationCode"
        >
          <div className="chkbox_btn_align">
            <div className="chkbox">
              <button
                className="login_submit_button font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative"
                onClick={() => { navigateToLoginPage() }}
              >
                <Trans>Back</Trans>
              </button>
            </div>

            <div className="signin_btn">
              <button
                className="login_submit_button font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative"
                type="submit"
                language={language}
              >
                <Trans>Submit</Trans>
                {isSendingCode && (
                  <Photo
                    src="loader_64.gif"
                    className="spinner"
                    alt=""
                  />
                )}
              </button>
            </div>
          </div>

        </Form.Group>
      </Form>
    );
  }

  function renderConfirmationForm() {
    return (
      <Form onSubmit={confirmVerificationCode} autoComplete="off">
        <Form.Group controlId="code">
          <div className="input_custom_form_style">
            <Form.Label>
              <Trans>Confirmation Code</Trans>
            </Form.Label>
          </div>

          <Form.Control
            type="text"
            name="code"
            value={formValues.code}
            required
            onChange={handleChange}
            className="input_style"
          />
        </Form.Group>

        <Form.Group controlId="password">
          <div className="input_custom_form_style">
            <Form.Label>
              <Trans>Password</Trans>
            </Form.Label>
          </div>
          <div className="position_relative">
            <Form.Control
              type={passwordType}
              name="password"
              value={formValues.password}
              required
              onChange={handleChange}
              className="input_style"
            />
            {
              showEyeOption ? (<div className="eyeposition">
                {
                  showPassword ? (<div className="eyeopen" onClick={showPasswordValue}>
                    <Photo
                      src="eye_visibility_on.png"
                      className="width_20"
                      alt="eye_visibility_on"
                    />
                  </div>)
                    :
                    (<div className="eyeclose" onClick={hidePasswordValue}>

                      <Photo
                        src="eye_visibility_off.png"
                        className="width_20"
                        alt="eye_visibility_off"
                      />
                    </div>)
                }
              </div>) : null
            }
          </div>
        </Form.Group>

        <Form.Group controlId="confirmPassword">
          <div className="input_custom_form_style">
            <Form.Label>
              <Trans>Confirm Password</Trans>
            </Form.Label>
          </div>
          <div className="position_relative">
            <Form.Control
              type={confirmPasswordType}
              name="confirmPassword"
              value={formValues.confirmPassword}
              required="required"
              onChange={handleChange}
              className="input_style"
            />
            {
              showConfirmPasswordEyeOption ? (<div className="eyeposition">
                {
                  showConfirmPassword ? (<div className="eyeopen" onClick={showConfirmPasswordValue}>
                    <Photo
                      src="eye_visibility_on.png"
                      className="width_20"
                      alt="eye_visibility_on"
                    />
                  </div>)
                    :
                    (<div className="eyeclose" onClick={hideConfirmPasswordValue}>
                      <Photo
                        src="eye_visibility_off.png"
                        className="width_20"
                        alt="eye_visibility_on"
                      />
                    </div>)
                }
              </div>) : null
            }
          </div>
        </Form.Group>

        {passwordMisMatch ? (
          <div style={{ color: "red", fontSize: "12px" }}>{passwordMisMatchError}</div>
        ) : null}

        <div className="password_policy">Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols</div>

        <Form.Group
          className="sign-bottom-section"
          controlId="confirmVerificationCode"
        >
          <div className="chkbox_btn_align">
            <div className="chkbox">

            </div>

            <div className="signin_btn">
              <button
                className="login_submit_button font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative"
                type="submit"
                language={language}
              >
                <Trans>Confirm</Trans>
                {isConfirming && (
                  <Photo
                    src="loader_64.gif"
                    className="spinner"
                    alt=""
                  />
                )}
              </button>
            </div>
          </div>

        </Form.Group>
      </Form>
    );
  }

  return (
    <div>
      <Helmet>
        <Link rel="canonical" href={canonicalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
      </Helmet>
      <div
        className="display_flex flex_wrap height_100p"
        lang={language}
      >
        <div
          className="login_left_side blue_color_fifth_bg height_100p"
          lang={language}
        >
          <div
            className="width_100 height_100p padding_24 position_relative display_flex flex_align_center justify_center"
            lang={language}
          >
            <div className="logo">
              <div className="display_flex justify_center">
                <Link to="/" language={language}>
                  <Photo src="Logo Icon.svg" alt="" className="powered_logo" />
                </Link>
              </div>
              <div className="displa_flex justify_center margin_top_36">
                <Photo src="Tagline.svg" alt="" className="powered_logo" />
              </div>
            </div>
            <div className="powered_by font_12 muller_light">
              <Trans>Powered</Trans>
              <Trans>by</Trans>
              <Photo
                src="Stellosysyw-Logo.svg"
                alt=""
                className="powered_logo width_48"
              />
              <span className="login_copyright">
                <Trans>Copyright 2021 Stellosys All rights Reserved.</Trans>
              </span>
            </div>
          </div>
        </div>

        <div
          className="login_right_side fifth_blue_bg height_100p display_flex flex_align_center login_main position_relative"
          language={language}
        >
          <div
            className="form_section"
            lang={language}
          >
            <div className="login_alert_msg">
              {alert_type === "SUCCESS" ? (
                <SuccessAlert message={alert_message} />
              ) : null}

              {alert_type === "ERROR" ? (
                <ErrorAlert message={alert_message} />
              ) : null}
            </div>

            <div className="logo_section">
              <Link to="/" language={language}>
                {" "}
                <Photo
                  src="LogiPulse-Logo-H48.svg"
                  className="full_logo_width"
                  alt="LOGIPULSE"
                />
              </Link>
            </div>
            <div className="login_header hard_blue_color muller_bold margin_top_48 margin_bottom_24 font_24">
              <Trans>Forgot Password</Trans>
            </div>
            <div className="field_section width_360">
              {!codeSent
                ? renderRequestCodeForm()
                : renderConfirmationForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
