const GATSBY_DOMAIN = process.env.GATSBY_DOMAIN;

/** @class */
export class CustomStorage {
    /**
     * This is used to set a specific item in storage
     */
    static setItem(key, value) {
        let domainName = GATSBY_DOMAIN;
        let date = new Date();
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
        let expires = "expires=" + date.toUTCString();

        if (key.includes("idToken") || key.includes('refreshToken') || key.includes('LastAuthUser')) {
            document.cookie = key + "=" + value + "; " + expires + "; domain=" + domainName + "; path=/";
        } else {
            // if (key.includes("refreshToken")) {
            //     let [header, payload, signature] = value.split(".");
            //     let headerAndPayload = header + '.' + payload;

            //     localStorage.setItem(key, headerAndPayload);
            //     document.cookie = key + "=" + signature + "; " + expires + "; domain=" + domainName + "; path=/";
            // } else {
            localStorage.setItem(key, value);
            // }
        }
    }

    /**
     * This is used to get a specific key from storage
     */
    static getItem(key) {
        let value = '';
        let signature = '';
        let headerAndPayload = '';

        if (key.includes("idToken") || key.includes('refreshToken') || key.includes('LastAuthUser')) {
            value = this.readCookieByKeyName(key);
        } else {
            // if (key.includes("refreshToken")) {
            //     headerAndPayload = localStorage.getItem(key);
            //     signature = this.readCookieByKeyName(key);
            //     value = headerAndPayload + '.' + signature;
            // } else {
            value = localStorage.getItem(key);
            // }
        }

        return value
    }

    /**
     * This is used to remove an item from storage
     */
    static removeItem(key) {

    }

    /**
     * This is used to clear the storage
     */
    static clear() {

    }


    static readCookieByKeyName(key) {
        let value = '';
        document.cookie.split(';').forEach((e) => {
            if (e.includes(key)) {
                value = e.split('=')[1]
            }
        });

        return value;
    }
}
