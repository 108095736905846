import React from "react"

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const LANGUAGE_ENG = "en"

export const buildCanonicalUrl = (language, data) => {

  if (data != undefined && data != null&&data.formatter != undefined && data.formatter != null) {
    const slug = data.formatter.slug

    return language === LANGUAGE_ENG && slug === 1
      ? GATSBY_LANDING_PAGE_URL + "/blog/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/blog/${slug}/`
  } 
  else return null
}

export const defaultRelationAlternatives = () => {
  return (
    <>
      <link
        rel="alternate"
        href="https://www.logipulse.com/ar/"
        hreflang="ar"
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/"
        hreflang="x-default "
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/nl/"
        hreflang="nl"
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/it/"
        hreflang="it"
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/ja/"
        hreflang="ja"
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/pt/"
        hreflang="pt"
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/es/"
        hreflang="es"
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/zh-Hant/"
        hreflang="zh-Hant"
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/zh/"
        hreflang="zh"
      />
      <link
        rel="alternate"
        href="https://www.logipulse.com/de/"
        hreflang="de"
      />
    </>
  )
}
