import React, { useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import Photo from "../components/Photo"
import "../styles/signinsuccesspage.scss"
import { Trans } from "gatsby-plugin-react-i18next"
import { graphql, navigate } from "gatsby"
import { Link, I18nextContext } from "gatsby-plugin-react-i18next"
import { Auth } from "aws-amplify"
import { getIdTokenFromCookie, getTokenDetailsByDecoding } from "../services/cookie-storage-service"
import { Helmet } from "react-helmet";
import { Fragment } from 'react';

const GATSBY_ROLE_TENANT_ADMIN = process.env.GATSBY_ROLE_TENANT_ADMIN;
const GATSBY_ROLE_SYSADMIN = process.env.GATSBY_ROLE_SYSADMIN;
const GATSBY_LOGIPULSE_APPLICATION_URL =
  process.env.GATSBY_LOGIPULSE_APPLICATION_URL;
const GATSBY_CONFIGURATOR_URL = process.env.GATSBY_CONFIGURATOR_URL;
const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;

function SigninSuccessPage() {
  console.log('SigninSuccessPage enetered', new Date());

  const { language } = React.useContext(I18nextContext);

  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/signinsuccesspage/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/signinsuccesspage/`

  const canonicalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
  const hrefUrl = buildHrefUrl();
  const [isApplicationLoading, setApplicationLoading] = useState(false);
  const [isConfiguratorLoading, setConfiguratorLoading] = useState(false);

  const handleSubmit = async e => {
    console.log('SigninSuccessPage handleSubmit enetered', new Date());
    e.preventDefault();

    let btnValue = e.target.value
    let currentLanguage = `${language}`
    let idTokenFromCookie = await getIdTokenFromCookie();

    if (btnValue === "Application") {
      setApplicationLoading(true);
    } else {
      setConfiguratorLoading(true);
    }

    if (idTokenFromCookie === "") {
      if (currentLanguage === "en") {
        navigate("/login")
      } else {
        navigate(`/${currentLanguage}/login`)
      }
    } else {
      // let user = await Auth.currentAuthenticatedUser();
      // let userAttributes = await Auth.currentUserInfo(user);
      // let userRole = userAttributes.attributes["custom:tenantUserRole"]
      // userRole = userRole.replaceAll(/\s/g, "");
      let tokenDetails = getTokenDetailsByDecoding(idTokenFromCookie);
      let userRole = tokenDetails.tenantUserRole;

      if (btnValue === "Application") {
        setApplicationLoading(false);
        if (
          GATSBY_ROLE_TENANT_ADMIN === userRole ||
          GATSBY_ROLE_SYSADMIN === userRole
        ) {
          if (currentLanguage === "en") {
            navigate("/location")
          } else {
            navigate(`/${currentLanguage}/location`)
          }
        } else {
          window.open(
            GATSBY_LOGIPULSE_APPLICATION_URL + "/logipulse/mail/inbox",
            "_blank"
          )
        }
      } else if (btnValue === "Configurator") {
        setConfiguratorLoading(false);
        window.open(
          GATSBY_CONFIGURATOR_URL + "/Summary",
          "_blank"
        )
      }
    }
  }

  const handleMouseMoveEvent = async (event) => {
    console.log('handleMouseMoveEvent++++++', event.type);

    let currentLanguageCode = `${language}`
    let idTokenFromCookie = await getIdTokenFromCookie()

    if (idTokenFromCookie === "") {
      if (currentLanguageCode === "en") {
        navigate("/login")
      } else {
        navigate(`/${currentLanguageCode}/login`)
      }
    }
  }

  return (
    <Fragment>
      <Helmet>
        <Link rel="canonical" href={canonicalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
      </Helmet>
      <main className="purchase-page" lang={language}>
        <div className="purchase-page-div" lang={language}>
          <div className="container" lang={language}>
            <div className="row purchase-total signin_page_total" lang={language}>
              <div className="col-md-6">
                <div
                  className="contents ipadsignin"
                  lang={language}
                >
                  <Link to="/" language={language}>
                    {" "}
                    <Photo
                      src="LogiPulse-Logo-H48.svg"
                      className="photo-size"
                      alt=""
                    />{" "}
                  </Link>
                  <p>
                    <Trans>
                      Congratulations on becoming a synergist in digitizing your
                      logistics business. The express journey that begins here
                      is going to make you escalate above the horizon you
                      envisioned.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="col-md-6" onMouseMove={handleMouseMoveEvent}>
                <div className="contents" lang={language}>
                  {/* <div className="saas-head">Please Select the navigation page?</div> */}
                  <div className="align-nav-btn">
                    <Form>
                      <button
                        type="submit"
                        className="navigation-btn position_relative"
                        name="btn"
                        value="Configurator"
                        onClick={handleSubmit}
                      >
                        <Trans>Configurator</Trans> &nbsp;
                        {isConfiguratorLoading && (
                          <Photo src="loader_64.gif" className="signin_success_spinner" alt="" />
                        )}
                      </button>

                      <button
                        type="submit"
                        className="navigation-btn position_relative"
                        name="btn"
                        value="Application"
                        onClick={handleSubmit}
                      >
                        <Trans>Application</Trans>&nbsp;
                        {isApplicationLoading && (
                          <Photo src="loader_64.gif" className="signin_success_spinner" alt="" />
                        )}
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer-navigation">
          <div className="purchase-footer-bottom">
            <div className="container">
              <div className="row align-content-center">
                <div className="col-md-4 power">
                  <div>
                    <Trans>Powered by</Trans>
                  </div>
                  <Photo
                    src="Stellosysyw-Logo.svg"
                    alt=""
                    className="powered_logo"
                  />
                </div>
                <div className="col-md-4 copyright">
                  <div>
                    <Trans>Copyright 2021 Stellosys</Trans>
                    <br /> <Trans>All rights Reserved.</Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </Fragment>
  )
}

export default SigninSuccessPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
