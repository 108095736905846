import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import Photo from '../components/Photo';
import "../styles/emailverification.scss";
import "../styles/common_style.scss";
import Modal from 'react-modal';
import { navigate } from "gatsby";
import { Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { Link, I18nextContext } from "gatsby-plugin-react-i18next";
import SuccessAlert from '../components/common/SuccessAlert';
import ErrorAlert from '../components/common/ErrorAlert';
import { Helmet } from "react-helmet";
import { buyLicense, confirmRegistration, resendOTP } from "../services/tenant-identity-service";
import { Create, Confirm } from "../constant/operation-mode-enum";
import { useTranslation } from "react-i18next";
import { getValueMessages } from '../services/language-service';
import { MESSAGE } from '../constant/message-enum';

const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // width: '800px',
        // padding: '48px'
    }
};

const alertBoxStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        // padding: '48px',
    }
};

const initialFormValue = {
    fullName: "",
    companyName: "",
    countryGuid: "",
    email: "",
    mobileNo: "",
    otp: ""
}

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;

function Emailverification() {
    const { language } = React.useContext(I18nextContext);
    const { t } = useTranslation();
    const buildCanonicalUrl = () =>
        language === "en"
            ? GATSBY_LANDING_PAGE_URL + "/emailverification/"
            : GATSBY_LANDING_PAGE_URL + `/${language}/emailverification/`;

    const canonicalUrl = buildCanonicalUrl();
    const buildHrefUrl = () =>
        language === "en"
            ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
            : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
    const hrefUrl = buildHrefUrl();

    const [alert_type, setAlertType] = useState("");
    const [alert_message, setAlertMessage] = useState('');
    const [formValues, setformValues] = useState(initialFormValue);
    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setModelOpen] = useState(false);
    const [checked, setCheckBoxValue] = useState(false);
    const [operationMode, setOperationMode] = useState('');
    const [showVerificationOTP, setShowVerificationOTP] = useState(false);
    const [isAlertModalOpen, setAlertModelOpen] = useState(false);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userinfo'));

        if (localStorage.getItem('userinfo')) {
            let value = { ...initialFormValue };
            if (userData.email !== undefined) {
                value['email'] = userData.email;
            }
            else {
                value['email'] = '';
            }

        } else {
            let currentLanguage = `${language}`;
            if (currentLanguage === 'en') {
                navigate('/');
            }
            else {
                navigate(`/${currentLanguage}/`);
            }
        }
    }, [language]);

    const handleAlertModalClose = async (event) => {
        setAlertModelOpen(false);
    }

    const handleModalOpen = event => {
        setModelOpen(true);
    }

    const handleModalClose = event => {
        setModelOpen(false);
    }

    const handleChange = (event) => {
        const value = { ...formValues };
        value[event.target.name] = event.target.value;
        setformValues(value);
    }

    // const validate = (type) => {

    //     let emailError = '';
    //     let otpError = ''

    //     if (!formValues.email) {
    //         emailError = 'Email cannot be Blank.';
    //     }

    //     else if (!formValues.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g)) {
    //         emailError = 'Email is Invalid.';
    //     }

    //     if (!formValues.otpnum && operationMode === Confirm) {
    //         otpError = 'OTP cannot be Blank.';
    //     }

    //     if (emailError || otpError) {
    //         this.setState({ emailError, otpError });
    //         return false;
    //     }

    //     return true;
    // }

    const resendConfirmationCode = (event) => {
        event.preventDefault();
        let currentLanguage = `${language}`;
        setAlertType("");
        setAlertMessage("");

        let inputData = {
            "email": formValues.email
        }

        resendOTP(inputData).then(async (res) => {
            let valueFields = { V1: formValues.email };
            let validationMessage = await getValueMessages(MESSAGE.OTP_SENT_TO_MAIL, currentLanguage, JSON.stringify(valueFields));
            setAlertType("SUCCESS");
            await setAlertMessage(validationMessage);
            await setAlertModelOpen(true);
        }).catch(err => {
            let errMessage = err.response.data.errorMessage;
            setAlertType("ERROR");
            setAlertMessage(errMessage);
            setAlertModelOpen(true);
        });
    }

    const handleCheck = () => {
        setCheckBoxValue(!checked);
    }

    const clearAlertType = () => {
        setAlertType("");
        setAlertMessage("");
    }

    const submitHandler = (event) => {
        event.preventDefault();
        clearAlertType();
        let userData = JSON.parse(localStorage.getItem('userinfo'));
        userData['email'] = formValues.email;

        let inputData = { ...userData };
        // let isValid = validate(type);
        let isValid = true;

        if (!checked) {
            setAlertType("ERROR");
            setAlertMessage("You must agree with terms and conditions.");
            setAlertModelOpen(true);
        }
        else if (isValid) {
            if (operationMode === Create) {
                setLoading(true);
                buyLicense(inputData).then(res => {
                    setLoading(false);
                    setShowVerificationOTP(true);
                    let errMessage = res.data;
                    setAlertType("SUCCESS");
                    setAlertMessage(errMessage);
                    setAlertModelOpen(true);
                }).catch((err) => {
                    setLoading(false);
                    if (err.response) {
                        let errMessage = err.response.data.errorMessage;
                        setAlertType("ERROR");
                        setAlertMessage(errMessage);
                        setAlertModelOpen(true);
                    }
                });
            }
            else {
                setLoading(true);
                let inputObj = {
                    "email": formValues.email,
                    "confirmationCode": formValues.otp
                }

                confirmRegistration(inputObj).then(res => {
                    setLoading(false);
                    localStorage.setItem('userinfo', JSON.stringify(inputData));
                    navigateToCredentialPage(event);
                }).catch(err => {
                    setLoading(false);
                    if (err.response) {
                        let errMessage = err.response.data.errorMessage;
                        setAlertType("ERROR");
                        setAlertMessage(errMessage);
                        setAlertModelOpen(true);
                    }
                });
            }
        }
    }

    const navigateToCredentialPage = async (event) => {
        event.preventDefault();
        let currentLanguage = `${language}`;

        if (currentLanguage === 'en') {
            navigate("/credentialspage");
        }
        else {
            navigate(`/${currentLanguage}/credentialspage`);
        }
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Verification</title>
                <Link rel="canonical" href={canonicalUrl} />
                <Link rel="alternate" href={hrefUrl} hreflang={language} />
                <meta name="description" content="" />
                <meta name="Keywords" content="web development, companypage" data-react-helmet="true" />
            </Helmet>
            <main className="purchase-page height_100_percent_page" lang={language}>
                <div className="purchase-page-div height_100_percent_page_div" lang={language}>
                    <div className="container">
                        {/* {
                            alert_type === 'SUCCESS' ? (<SuccessAlert message={alert_message} />) : null
                        }

                        {
                            alert_type === 'ERROR' ? (<ErrorAlert message={alert_message} />) : null
                        } */}

                        <div className="row purchase-total" lang={language}>
                            <div className="col-md-6">
                                <div className="contents ipademailveri" lang={language}>
                                    <Link to="/" language={language}>{" "}<Photo src="LogiPulse-Logo-H48.svg" className="photo-size" alt="" />{" "}</Link>
                                    <p><Trans>Hope you are enjoying the transit, to complete the sign-up procedure please verify your email, so that we know that you are accessible.</Trans></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contents" lang={language}>
                                    <div className="purchase_heading_section" lang={language}>
                                        <div className="profile"><b><Trans>Verification</Trans></b></div>
                                        <div className="purchase_progress">
                                            <ul id="progressbar_purchase">
                                                <li></li>
                                                <li className="active"></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <fieldset disabled={isLoading}>
                                        <Form onSubmit={submitHandler} autoComplete="off">
                                            <Form.Group controlId="formBasicEmail" lang={language}>
                                                <Form.Label><Trans>Email</Trans></Form.Label>
                                                <Form.Control
                                                    type="email" name="email"
                                                    value={formValues.email}
                                                    autoFocus
                                                    placeholder={t("Enter your Email")}
                                                    required disabled={showVerificationOTP}
                                                    onChange={handleChange} />
                                                {
                                                    showVerificationOTP ? (
                                                        <Fragment>
                                                            <div className="align-item">
                                                                <div className="ok-msgs"><Trans>Your OTP has been mailed.</Trans></div>

                                                                <div>
                                                                    <Link to="#" className="resend_otps" onClick={resendConfirmationCode}><Trans>Resend OTP</Trans></Link>
                                                                </div>
                                                            </div>

                                                            <div className="otp_form_group_arr">

                                                                <Form.Group controlId="formBasicOtp">
                                                                    <Form.Label className="otp_content"><Trans>OTP</Trans></Form.Label>
                                                                    <Form.Control
                                                                        type="text" name="otp"
                                                                        value={formValues.otp}
                                                                        autoFocus
                                                                        onChange={handleChange}
                                                                        required
                                                                    />
                                                                </Form.Group>

                                                            </div>
                                                        </Fragment>

                                                    ) : null
                                                }

                                            </Form.Group>

                                            <div id="main" lang={language}>
                                                <div className="form-group form-check terms_condition_button" lang={language}>
                                                    <div className="chkbox-agree-section" lang={language}>
                                                        <input type="checkbox" className="form-check-inputs" onChange={handleCheck} defaultChecked={checked} />

                                                        <div className="agree-section" lang={language}>
                                                            <span><Trans>I agree</Trans> <Link to="#" onClick={handleModalOpen}><Trans>Terms & Conditions</Trans></Link></span>
                                                        </div>
                                                    </div>
                                                    <div className="align-btn">
                                                        {
                                                            !showVerificationOTP ? (
                                                                <button type="submit" className="submit_button_common font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative" disabled={isLoading} onClick={() => { setOperationMode(Create) }}><Trans>NEXT</Trans>
                                                                    {
                                                                        isLoading ? (
                                                                            <Photo src="loader_64.gif" className="spinner" alt="" />
                                                                        ) : null
                                                                    }
                                                                </button>
                                                            ) : <button type="submit" className="submit_button_common font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative" disabled={isLoading} onClick={() => { setOperationMode(Confirm) }}><Trans>NEXT</Trans>
                                                                {
                                                                    isLoading ? (
                                                                        <Photo src="loader_64.gif" className="spinner" alt="" />
                                                                    ) : null
                                                                }
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </fieldset>


                                    <Modal
                                        isOpen={isModalOpen}
                                        onRequestClose={handleModalClose}
                                        contentLabel="Example Modal In Gatsby"
                                        style={customStyles} className="modal_padding"
                                    >
                                        <div className="modal-header">
                                            <h5 className="modal-title ml-0">Terms & Conditions</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleModalClose}>
                                                <span aria-hidden="true" className="imges"> <Photo src="close-black.svg" alt="" /></span>
                                            </button>
                                        </div>
                                        <div className="modal-body text-left content_scroll height_400">
                                            <div className="termscondition"><b>USE OF SITE</b></div>
                                            <div className="termscondition_content">The contents of this Site are the property of STELLOSYS or are licensed to STELLOSYS by a third party. Except as stated above, or unless otherwise expressly stated, no license is granted herein expressly, implied or otherwise under any patent, trademark, copyright or other intellectual property of STELLOSYS or any third party.</div>
                                            <div className="termscondition"><b>ACCESS AND INTERFERENCE</b></div>
                                            <div className="termscondition_content">The contents of this Site are the property of STELLOSYS or are licensed to STELLOSYS by a third party. Except as stated above, or unless otherwise expressly stated, no license is granted herein expressly, implied or otherwise under any patent, trademark, copyright or other intellectual property of STELLOSYS or any third party.</div>
                                            <div className="termscondition"><b>NON-DISCLOSURE</b></div>
                                            <div className="termscondition_content">Unless otherwise agreed, each contracting party will treat any information (including documentation, samples etc.) disclosed to it by STELLOSYS as a trade secret, use such information solely for the purpose of the Contract, and refrain from disclosing it to any third parties.</div>
                                            <div className="termscondition"><b>LIMITATION OF LIABILITY</b></div>
                                            <div className="termscondition_content">Except for actions or claims arising from the gross negligence or intentional or wilful misconduct, liability to STELLOSYS shall be limited to the compensation fees based upon the contract.</div>
                                            <div className="termscondition"><b>LINKS TO THIRD PARTY SITES</b></div>
                                            <div className="termscondition_content">STELLOSYS does not review or control and is thus not responsible for any third-party sites or their content. Such links should not be assumed to constitute an endorsement or recommendation by STELLOSYS. By clicking on any of these links, you are leaving the STELLOSYS Site and accessing these other sites at your own risk.</div>

                                            <div className="termscondition"><b>COMMUNITY FORUMS</b></div>
                                            <div className="termscondition_content">STELLOSYS may provide chat room, bulletin boards, blogs or other user forums (“Forums”) that allow third parties to post content and comments. STELLOSYS has the right, but not the obligation, to review or monitor third postings (“Submissions”) and to remove Submissions that contains material STELLOSYS deems offensive, abusive, defamatory, obscene or otherwise unacceptable. However, STELLOSYS shall not be liable for claims arising from those Submissions whether arising under the laws of copyright, libel, privacy, obscenity or otherwise.</div>

                                            <div className="termscondition"><b>GOVERNING LAW</b></div>
                                            <div className="termscondition_content">These Terms shall be governed in all respects by the laws of the <b>The Cybersecurity Framework (NCFS)</b></div>

                                            <div className="termscondition"><b>IMPORT/EXPORT</b></div>
                                            <div className="termscondition_content">You may not access, download, use, or export information (including software, products or services) contained on this Site in violation of any applicable laws or regulations including, but not limited to the export laws of the European Union and United States.</div>

                                            <div className="termscondition"><b>PRIVACY</b></div>
                                            <div className="termscondition_content">For information on how STELLOSYS handles your personal information, see our Privacy Policy.</div>

                                            <div className="termscondition"><b>INDEMNIFICATION</b></div>
                                            <div className="termscondition_content">You agree to indemnify, defend and hold harmless, STELLOSYS, its subsidiaries, affiliates, joint ventures, business partners, licensors, employees, agents and third-party information providers from and against all losses, expenses, damages and costs, including attorney fees, resulting from any violation of these Terms (including negligent or wrongful conduct) by you or your use and access to the Site.</div>



                                        </div>
                                        <div className="modal-footer">
                                            <div className="learnmore"><FontAwesomeIcon icon={faExclamationCircle} /><span className="learnmore-text">Learn more</span></div>
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleModalClose}>I AGREED</button>
                                        </div>
                                    </Modal >

                                    {/* Modal Start: - Alert modal */}
                                    <Modal
                                        isOpen={isAlertModalOpen}
                                        onRequestClose={handleAlertModalClose}
                                        contentLabel="Example Modal In Gatsby"
                                        style={alertBoxStyle} className="modal_padding"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <div className="modal-body">
                                            <div className="termscondition_content pb-0">{alert_message}</div>
                                        </div>
                                        <div className="modal-footer text_align_center pl-0 pr-0 mt-0 justify_space_around">
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleAlertModalClose}>OK</button>
                                        </div>

                                    </Modal >
                                    {/* Modal End: - Alert modal */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <footer className="purchase-footer">
                    <div className="purchase-footer-bottom">
                        <div className="container">
                            <div className="row align-content-center">
                                <div className="col-md-4 power">
                                    <div><Trans>Powered by</Trans></div>
                                    <Photo src="Stellosysyw-Logo.svg" alt="powered_logo" className="powered_logo" />
                                </div>
                                <div className="col-md-4 copyright">
                                    <div>
                                        <Trans>Copyright 2021 Stellosys</Trans><br /> <Trans>All rights Reserved.</Trans>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </main >
        </div>
    )
}

export default Emailverification;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`